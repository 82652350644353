import http from "@/plugins/https";
// 列表
export function getAreas(data) {
  return http({
    url: `/baseArea/list?id=${data.id}`,
    method: 'GET',
  })
}

// 详情
export function showArea(data) {
  return http({
    url: `/baseArea/get`,
    params:data,
    method: 'GET',
  })
}

// 根据身份获取可以访问的省市县
export function getDefaultArea() {
//  验证用户身份
  let admin = localStorage.getItem("admin");
  let areaId = 1;
  let identity = 0;
  // 非管理员
  if (admin == "0") {
    let province = JSON.parse(localStorage.getItem("province"));
    if (province.id) {
      areaId = parseInt(province.id);
      identity = 1;
    }
    let city = JSON.parse(localStorage.getItem("city"));
    if (city.id) {
      areaId = parseInt(city.id);
      identity = 2;
    }
    let area = JSON.parse(localStorage.getItem("area"));
    if (area.id) {
      areaId = parseInt(area.id);
      identity = 3;
    }
  } else {
    areaId = 1;
  }

  return {
    id:areaId,
    identity:identity,
  }
}