import http from "@/plugins/httpss";
// 区域主要超标指标
export function getConsumAreaBadQuality(data) {
  return http({
    url: `/api/water-quality/qualityConsum/getConsumAreaBadQuality`,
    params:data,
    method: "GET"
  });
}
// 各市主要超标指标
export function getConsumBadQuality(data) {
  return http({
    url: `/api/water-quality/qualityConsum/getConsumBadQuality`,
    params:data,
    method: "GET"
  });
}
// 指标超标分析
export function getAreaBadQualityTable(data) {
  return http({
    url: `/api/water-quality/qualityConsum/getAreaBadQualityTable`,
    params:data,
    method: "GET"
  });
}
// 各市主要超标指标地图数据
export function getConsumMap(data) {
  return http({
    url: `/api/water-quality/qualityConsum/getConsumAreaBadQuality`,
    params:data,
    method: "GET"
  });
}
// 水质超标统计
export function getAreaBadDetectTable(data) {
  return http({
    url: `/api/water-quality/qualityConsum/getAreaBadDetectTable`,
    params:data,
    method: "GET"
  });
}
// 获取超标指标列表
export function getDetectList(data) {
  return http({
    url: `/api/water-quality/qualityConsum/detectList`,
    params:data,
    method: "GET"
  });
}
// 获取省市超标指标合格率情况
export function getConsumMultiQualityByDetect(data) {
  return http({
    url: `/api/water-quality/qualityConsum/getConsumMultiQualityByDetect`,
    params:data,
    method: "GET"
  });
}