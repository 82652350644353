import http from "@/plugins/https";
// 大统计信息
export function getConsumIndustry(data) {
  return http({
    url: `/consumIndustry/getConsumIndustry`,
    params:data,
    method: "GET"
  });
}

export function getConsumProject(data) {
  return http({
    url: `/consumIndustry/getConsumProject`,
    params:data,
    method: "GET"
  });
}

export function getAreaIndustries(data) {
  return http({
    url: `/consumIndustry/getAreaIndustries`,
    params: data,
    method: "GET"
  });
}

export function getIndustrieList(data) {
  return http({
    url: `/consumIndustry/getIndustries`,
    params:data,
    method: "GET"
  });
}
export function baseMenuList(data) {
  return http({
    url: `/baseMenu/list`,
    params: data,
    method: "GET"
  });
}