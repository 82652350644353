<template>
  <div class="survey" style="overflow-y: hidden;">
    <!-- 查询条件选择 -->
    <div class="industry-second">
      <div class="industry-second-up">
        <a-form
          class="ant-advanced-search-form"
          :form="form"
          @submit="handleSearch"
        >
          <a-row :gutter="24">
            <a-col :span="8">
              <!--<a-form-item label="行政区划：">-->
              <!--<a-select-->
              <!--mode="multiple"-->
              <!--style="width: 100%"-->
              <!--placeholder="请选择"-->
              <!--@change="handleChangeArea"-->
              <!--v-model="param.selectedAreaId"-->
              <!--&gt;-->
              <!--<a-select-option-->
              <!--:value="item.id"-->
              <!--v-for="(item, index) in areas"-->
              <!--:key="index"-->
              <!--&gt;-->
              <!--{{ item.name }}-->
              <!--</a-select-option>-->
              <!--</a-select>-->
              <!--</a-form-item>-->
              <a-form-item label="行政区划：">
                <a-cascader
                  :options="options"
                  @change="areaChange"
                  placeholder="请选择"
                  expandTrigger="hover"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="水样类型：">
                <a-select
                  mode="single"
                  style="width: 100%"
                  placeholder="请选择"
                  v-model="param.sampleType"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in sampleTypes"
                    :key="index"
                    @change="handleChangeSampleType"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
              <a-col :span="8">
                  <a-form-item label="水源类型：">
                      <a-select
                              mode="multiple"
                              style="width: 100%"
                              placeholder="请选择"
                              @change="handleChangeWaterSource"
                              v-model="param.waterTypes"
                      >
                          <a-select-option
                                  :value="item.value"
                                  v-for="(item, index) in arr3"
                                  :key="index"
                          >
                              {{ item.description }}
                          </a-select-option>
                      </a-select>
                  </a-form-item>
              </a-col>
          </a-row>
          <a-row :gutter="24" style="margin-top:20px">
          <a-col :span="5">
              <a-form-item label="时间：">
                  <a-select
                          mode="single"
                          style="width: 100%"
                          placeholder="请选择"
                          v-model="param.reportYear"
                          @change="handleChange"
                  >
                      <a-select-option v-for="i in year" :key="i">
                          {{ i }}
                      </a-select-option>
                  </a-select>
              </a-form-item>
          </a-col>
          <a-col :span="3">
              <a-form-item label="">
                  <a-select
                          mode="multiple"
                          style="width: 100%"
                          placeholder="请选择"
                          @change="handleChangeSeason"
                          v-model="param.season"
                  >
                      <a-select-option
                              :value="item.value"
                              v-for="(item, index) in seasons"
                              :key="index"
                      >
                          {{ item.description }}
                      </a-select-option>
                  </a-select>
              </a-form-item>
          </a-col>
          <a-col :span="8">
              <a-form-item label="工程类型：">
                  <a-select
                          mode="multiple"
                          style="width: 100%"
                          placeholder="请选择"
                          @change="handleChangeProject"
                          v-model="param.projectTypes"
                  >
                      <a-select-option
                              :value="item.value"
                              v-for="(item, index) in arr4"
                              :key="index"
                      >
                          {{ item.description }}
                      </a-select-option>
                  </a-select>
              </a-form-item>
          </a-col>
            <a-col
              :span="8"
              :style="{ textAlign: 'right' }"
              style="margin-top: 5px;"
            >
              <a-button type="primary" html-type="submit">
                查询
              </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清除
              </a-button>
            </a-col>
          </a-row>
          <!--<a-row>-->
          <!---->
          <!--</a-row>-->
        </a-form>
      </div>
    </div>
    <!-- 超标指标分析 -->
    <div class="industry-six">
      <div class="industry-six-l">
        <div class="industry-thirdone-title">
          <div class="industry-thirdone-title-l">
            <div class="line"></div>
            <div class="title">超标指标</div>
          </div>
        </div>
        <div class="industry-six-l-con">
          <div class="industry-six-l-con-p">
            <div class="industry-six-l-con-l">
              <div class="industry-six-l-con-top">
                超标指标：{{ standard_count }}项
              </div>
              <div class="industry-six-l-con-top">
                超标工程：{{ project_count }}处
              </div>
              <div class="industry-six-l-con-top">
                超标工程覆盖人口：{{ badCoverPeo }}万人
              </div>
            </div>
            <div class="industry-six-l-con-r">
              <div class="industry-six-l-con-top">
                检测指标：{{ detectionNum }}项
              </div>
              <div class="industry-six-l-con-top">
                检测工程：{{ coverPla }}处
              </div>
              <div class="industry-six-l-con-top">
                检测工程覆盖人口：{{ coverPeo }}万人
              </div>
            </div>
          </div>
          <div
            class="industry-six-l-con-btn"
            @click="router('/index/waterQuality/index/analysisDetail')"
          >
            详情
          </div>
        </div>
      </div>
      <div class="industry-six-r">
        <div class="industry-thirdone-title">
          <div class="industry-thirdone-title-l">
            <div class="line"></div>
            <div class="title">超标指标分析</div>
          </div>
          <div class="industry-thirdone-title-r">
            <div style="margin-right:50px;display: flex;">
              <div
                class="industry-thirdone-title-rdone"
                :class="
                  tabListeIndex1 == index
                    ? 'industry-thirdone-title-rselect'
                    : ''
                "
                v-for="(item, index) in tabListe"
                :key="index"
                @click="tabListeCheck1(index)"
              >
                {{ item }}
              </div>
            </div>

            <div class="industry-progress-tab">
              <div
                class="industry-progress-tabone"
                :class="tabListeIndex2 == index ? 'progress-tabone-select' : ''"
                v-for="(item, index) in tabList"
                :key="index"
                @click="waterProject3(index)"
              >
                {{ item }}
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div style="position: relative;width:100%;height:100%">
          <div id="tuline3" style="position: relative;width:100%;height:110%">
            <div class="linesort">
              <img
                v-if="sortup1"
                src="@/assets/img/industry/sortud.png"
                @click="sort1()"
              />
              <img
                v-if="!sortup1"
                src="@/assets/img/industry/sortup.png"
                @click="sort1()"
              />
            </div>
            <div id="line6" ref="line6" style="width:90%;height:90%"></div>
          </div>
          <div id="tableline3" style="margin-top:20px">
            <a-table
              :columns="area_bad_quality_table_column"
              :data-source="area_bad_quality_table_data"
              bordered
              :pagination="false"
              size="middle"
              :scroll="{ x: 'calc(200px + 50%)', y: 500 }"
            />
          </div>
        </div>
      </div>
    </div>
      <!-- 超标指标合格率 -->
      <div class="industry-single">
          <div class="industry-single-inner">
              <div class="industry-thirdone-title">
                  <div class="industry-thirdone-title-l">
                      <div class="line"></div>
                      <div class="title" style="width:400px">{{titleData}}{{titleDataRate}}</div>
                  </div>
                  <div class="industry-thirdone-title-r" style="justify-content: space-between;margin-top: 20px;" v-if="standard_count !=0">
                      <div style="margin-right:50px;display: flex;">
                          <div
                                  class="industry-thirdone-title-rdone"
                                  :class="
                                      tabChartIndex == index
                                        ? 'industry-thirdone-title-rselect'
                                        : ''
                                    "
                                  v-for="(item, index) in tabListe2"
                                  :key="index"
                                  @click="tabCheckChart(index)"
                          >
                              {{ item }}
                          </div>
                      </div>
                  </div>
              </div>
              <div class="industry-thirdone-title">
                  <div class="industry-thirdone-title-r" style="justify-content: space-between;" v-if="standard_count !=0">
                      <div class="industry-progress-tab" style="width:auto;">
                          <div
                                  class="industry-progress-tabone"
                                  v-for="(item, index) in detectIdList"
                                  :key="index"
                                  :class="tabTypeIndex == item.id ? 'progress-tabone-select-borderActive' : 'progress-tabone-select-border'"
                                  @click="changeType(item.id)"
                          >
                              {{ item.descChinese }}
                              <div></div>
                          </div>
                      </div>
                  </div>
              </div>
              <a-empty description="暂无超标指标数据"  v-if="standard_count ==0"/>
              <div style="position: relative;width:100%;height:100%;overflow:hidden" v-else>
                  <div id="tabChart" style="position: relative;width:100%;height:100%"  v-if="tabChartIndex==0">
                      <div id="lineRate" ref="lineRate" style="width:100%;" :style="{height: chartHeight}"></div>
                  </div>
                  <div id="tabTable" style="margin-top:20px" v-else>
                      <a-table
                              :columns="city_rate_column"
                              :data-source="city_rate_table_data"
                              bordered
                              :pagination="false"
                              size="middle"
                              :scroll="{ x: 'calc(200px + 50%)', y: 500 }"
                      />
                  </div>
              </div>
          </div>
      </div>
      <div class="industry-single">
        <div class="industry-single-inner">
          <div class="industry-thirdone-title">
              <div class="industry-thirdone-title-l">
                  <div class="line"></div>
                  <div class="title" style="width:400px">{{titleData}}{{titleDataNumber}}</div>
              </div>
              <div class="industry-thirdone-title-r" style="justify-content: space-between;margin-top: 20px;" v-if="standard_count !=0">
                  <div style="margin-right:50px;display: flex;margin-right:20px;">
                      <div
                              class="industry-thirdone-title-rdone"
                              style="width:auto;padding:0 8px;"
                              :class="
                  tabTypeInner == index
                    ? 'industry-thirdone-title-rselect'
                    : ''
                "
                              v-for="(item, index) in typeList"
                              :key="index"
                              @click="changeTypeInner(index)"
                              v-if="tabChartNumIndex==0"
                      >
                          {{ item }}
                      </div>
                  </div>
                  <div style="margin-right:50px;display: flex;">
                      <div
                              class="industry-thirdone-title-rdone"
                              :class="
                  tabChartNumIndex == index
                    ? 'industry-thirdone-title-rselect'
                    : ''
                "
                              v-for="(item, index) in tabListe3"
                              :key="index"
                              @click="tabCheckChartNum(index)"
                      >
                          {{ item }}
                      </div>
                  </div>
              </div>
          </div>
            <div class="industry-thirdone-title">
            <div class="industry-thirdone-title-r" style="justify-content: space-between;" v-if="standard_count !=0">
                <div class="industry-progress-tab" style="width:auto;">
                    <div
                            class="industry-progress-tabone"
                            v-for="(item, index) in detectIdList"
                            :key="index"
                            :class="tabTypeNumIndex == item.id ? 'progress-tabone-select-borderActive' : 'progress-tabone-select-border'"
                            @click="changeNumType(item.id)"
                    >
                        {{ item.descChinese }}
                        <div></div>
                    </div>
                </div>
          </div>
            </div>
            <a-empty description="暂无超标指标数据" v-if="standard_count ==0"/>
            <div style="position: relative;width:100%;height:100%;overflow:hidden" v-else>
                <div id="tabChartNumber" style="position: relative;width:100%;height:100%"  v-if="tabChartNumIndex==0">
                    <div class="linesort">
                        <img
                                v-if="sortNum"
                                src="@/assets/img/industry/sortud.png"
                                @click="numSort()"
                        />
                        <img
                                v-else
                                src="@/assets/img/industry/sortup.png"
                                @click="numSort()"
                        />
                    </div>
                    <div id="lineNum" ref="lineNum" style="width:100%;" :style="{height: chartHeight}"></div>
                </div>
                <div id="tabTableNumber" style="margin-top:20px" v-else>
                    <a-table
                            :columns="city_number_column"
                            :data-source="city_number_table_data"
                            bordered
                            :pagination="false"
                            size="middle"
                            :scroll="{ x: 'calc(200px + 50%)', y: 500 }"
                    />
                </div>
            </div>
        </div>
      </div>
      <div class="industry-single" v-if="userLevel==3">
          <div class="industry-single-inner">
              <div class="industry-thirdone-title" style="display: block;">
                  <div class="industry-thirdone-title-l">
                      <div class="line"></div>
                      <div class="title" style="width:400px">{{titleData}}超标工程表</div>
                  </div>
              </div>
              <div style="position: relative;width:100%;height:100%;overflow:hidden">
                  <div id="tableCounty" style="margin-top:20px">
                      <a-table
                              :columns="county_column"
                              :data-source="county_column_table_data"
                              bordered
                              size="middle"
                              :pagination="{ pageSize: 20 }"
                              :scroll="{ x: 'calc(200px + 50%)', y: 500 }">
                              <a slot="reportNo" slot-scope="text, record" @click="toReportDetail(166)">编号</a>
                      </a-table>
                  </div>
              </div>
          </div>
      </div>
    <!-- 超标指标分析 -->
    <!--<div class="industry-six">-->
      <!--<div class="industry-six-r">-->
        <!--<div class="industry-thirdone-title">-->
          <!--<div class="industry-thirdone-title-l">-->
            <!--<div class="line"></div>-->
            <!--<div class="title" style="width:140px">各市主要超标指标</div>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div style="position: relative;width:100%;height:100%;overflow:hidden">-->
          <!--<div id="tuline3" style="position: relative;width:100%;height:100%">-->
            <!--<div class="linesort">-->
              <!--<img-->
                <!--v-if="sortup2"-->
                <!--src="@/assets/img/industry/sortud.png"-->
                <!--@click="sort2()"-->
              <!--/>-->
              <!--<img-->
                <!--v-if="!sortup2"-->
                <!--src="@/assets/img/industry/sortup.png"-->
                <!--@click="sort2()"-->
              <!--/>-->
            <!--</div>-->
            <!--<div id="lineRate" style="width:100%;height:230%;"></div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="industry-six-l">-->
        <!--<div class="industry-thirdone-title">-->
          <!--<div class="industry-thirdone-title-l">-->
            <!--<div class="line"></div>-->
            <!--<div class="title" style="width:200px">各市主要超标指标列表</div>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div id="tableline4" style="margin-top:20px">-->
          <!--<a-table-->
            <!--:columns="city_bad_quality_table_column"-->
            <!--:data-source="city_bad_quality_table_data"-->
            <!--bordered-->
            <!--size="middle"-->
            <!--:pagination="false"-->
            <!--:scroll="{ x: 'calc(400px )', y: 600 }"-->
          <!--/>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
    <!-- 地图 -->
    <!-- <div class="rates-one">
      <div class="rates-one-l">
        <div class="industry-thirdone-title">
          <div class="industry-thirdone-title-l">
            <div class="line"></div>
            <div class="title">水质达标地图查询</div>
          </div>
        </div> -->
    <!-- 地图 -->
    <!-- <div class="rates-onemap-con">
          <div class="legend"> -->
    <!-- <div class="legendone" v-for="(item, index) in map_titles" :key="index">
              <div class="block" :style="{ 'background': item.color }"></div>
              <div class="text">{{ item.name }}</div>
            </div>-->

    <!-- <div class="legendone">
              <div class="block" style="background: #f0d9b9;"></div>
              <div class="text">高氟水</div>
            </div>
            <div class="legendone">
              <div class="block" style="background: #badeea;"></div>
              <div class="text">高浊水</div>
            </div>
            <div class="legendone">
              <div class="block" style="background: #c3f6bd;"></div>
              <div class="text">微污染地表水</div>
            </div>
            <div class="legendone">
              <div class="block" style="background: #d2e4ca;"></div>
              <div class="text">苦咸水</div>
            </div>
            <div class="legendone">
              <div class="block" style="background: #cfb2b4;"></div>
              <div class="text">铁锰超标地下水</div>
            </div>
            <div class="legendone">
              <div class="block" style="background: #b6bad5;"></div>
              <div class="text">其他</div>
            </div>
          </div> -->
    <!-- <div class="title">{{ province }}</div> -->
    <!-- <div
            class="map"
            id="initShanxiMap"
            style="height:1100px;width:800px;position: absolute;top:-80px;left:15%"
          ></div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// 超标指标分析表头
const area_bad_quality_table_column = [
  {
    title: "检测指标",
    dataIndex: "detectName",
    key: "detectName",
    width: 100
    // fixed: 'left',
  },
  {
    title: "覆盖人口数",
    dataIndex: "badCoverPeo",
    key: "badCoverPeo",
    width: 110,
    sorter: (a, b) => a.badCoverPeo - b.badCoverPeo
  },
  {
    title: "工程处数 ",
    dataIndex: "badCoverPla",
    key: "badCoverPla",
    width: 110,
    sorter: (a, b) => a.badCoverPla - b.badCoverPla
  },
  {
    title: "人口占比 ",
    dataIndex: "badPeoPow",
    key: "badPeoPow",
    width: 110,
    sorter: (a, b) => a.badPeoPow - b.badPeoPow
  },
  {
    title: "工程占比",
    dataIndex: "badPlaPow",
    key: "badPlaPow",
    width: 110,
    sorter: (a, b) => a.badPlaPow - b.badPlaPow
  }
];

//各市主要超标指标表头
const city_bad_quality_table_column = [
  {
    title: "序号",
    dataIndex: "key",
    key: "key",
    width: 20,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "市、县 名称",
    dataIndex: "adName",
    key: "adName",
    width: 30,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "主要超标 指标",
    dataIndex: "detectName",
    key: "detectName",
    width: 30,
    align: "center"
  },
  {
    title: "超标 处数 ",
    dataIndex: "badDetectionNum",
    key: "badDetectionNum",
    width: 30,
    align: "center",
    sorter: (a, b) => a.badDetectionNum - b.badDetectionNum
  },
  {
    title: "超标水样占比 ",
    dataIndex: "badDetectionPow",
    key: "badDetectionPow",
    width: 35,
    align: "center",
    sorter: (a, b) => a.badDetectionPow - b.badDetectionPow
  }
];
//各市超标指标合格率表头
const city_rate_column = [
  {
    title: "序号",
    dataIndex: "key",
    key: "key",
    width: 20,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "市、县名称",
    dataIndex: "adName",
    key: "adName",
    width: 30,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "超标指标名称",
    dataIndex: "overStandName",
    key: "overStandName",
    width: 30,
    align: "center"
  },
  {
    title: "水样合格率（%）",
    dataIndex: "goodPow",
    key: "goodPow",
    width: 30,
    align: "center",
    sorter: (a, b) => a.goodPow - b.goodPow
  },
  {
    title: "合格水样覆盖人口占比（%） ",
    dataIndex: "goodPeoPow",
    key: "goodPeoPow",
    width: 35,
    align: "center",
    sorter: (a, b) => a.goodPeoPow - b.goodPeoPow
  },
  {
    title: "总水样数（件） ",
    dataIndex: "sampleNum",
    key: "sampleNum",
    width: 35,
    align: "center",
    sorter: (a, b) => a.sampleNum - b.sampleNum
  },
  {
    title: "检测水样覆盖人口（万人） ",
    dataIndex: "goodCoverPeo",
    key: "goodCoverPeo",
    width: 35,
    align: "center",
    sorter: (a, b) => a.goodCoverPeo - b.goodCoverPeo
  }
];
//各市超标指标数量表头
const city_number_column = [
  {
    title: "序号",
    dataIndex: "key",
    key: "key",
    width: 20,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "市、县名称",
    dataIndex: "adName",
    key: "adName",
    width: 30,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "超标指标名称",
    dataIndex: "overStandName",
    key: "overStandName",
    width: 30,
    align: "center"
  },
  {
    title: "超标水样数（件） ",
    dataIndex: "badSampleNum",
    key: "badSampleNum",
    width: 35,
    align: "center",
    sorter: (a, b) => a.badSampleNum - b.badSampleNum
  },
  {
    title: "超标水样覆盖人口（万人） ",
    dataIndex: "badCoverPeo",
    key: "badCoverPeo",
    width: 35,
    align: "center",
    sorter: (a, b) => a.badCoverPeo - b.badCoverPeo
  },
  {
    title: "总水样数（件）",
    dataIndex: "sampleNum",
    key: "sampleNum",
    width: 30,
    align: "center",
    sorter: (a, b) => a.sampleNum - b.sampleNum
  },
  {
    title: "检测水样覆盖人口（万人） ",
    dataIndex: "coverPeo",
    key: "coverPeo",
    width: 35,
    align: "center",
    sorter: (a, b) => a.coverPeo - b.coverPeo
  }
];
const county_column= [
  {
    title: "序列",
    dataIndex: "key",
    key: "key",
    width: 20,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "工程名称",
    dataIndex: "adName",
    key: "adName",
    width: 30,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "工程类型",
    dataIndex: "detectName",
    key: "detectName",
    width: 30,
    align: "center"
  },
  {
    title: "覆盖人口（万） ",
    dataIndex: "badCoverPeo",
    key: "badCoverPeo",
    width: 35,
    align: "center"
  },
  {
    title: "超标指标",
    dataIndex: "overStandardWaterSamples",
    key: "overStandardWaterSamples",
    width: 35,
    align: "center"
  },
  {
    title: "超标值",
    dataIndex: "totalWaterNum",
    key: "totalWaterNum",
    width: 30,
    align: "center"
  },
  {
    title: "限值",
    dataIndex: "limitValue",
    key: "limitValue",
    width: 35,
    align: "center"
  },
  {
    title: "报告编号",
    dataIndex: "reportNo",
    key: "reportNo",
    width: 35,
    align: "center",
    scopedSlots: { customRender: 'reportNo' }
  }
];
import { getAreas, getDefaultArea } from "@/api/area/index";
import { baseMenuList } from "@/api/industry/index";
import {
  getConsumAreaBadQuality,
  getConsumBadQuality,
  getConsumMap,
  getDetectList,
  getConsumMultiQualityByDetect
} from "@/api/water/analysis";
import { getAreaTreeList } from "@/api/basic/common";
export default {
  data() {
    return {
      chartHeight:null,
      form: this.$form.createForm(this, { name: "advanced_search" }),
      // 筛选条件
      areas: [], //测试数据/行政区划
      year: [], //测试数据/年度
      sampleTypes: [],
      seasons: [],
      arr3: [],
      arr4: [],
      // 筛选参数
      param: {
        defaultAreaId: undefined,
        selectedAreaId: undefined,
        areaId: undefined,
        reportYear: undefined,
        sampleType: undefined,
        season: undefined,
        waterTypes: [],
        projectTypes: [],
      },

      tabList: ["覆盖人口", "工程处数", "人口占比", "工程占比"], //tab
      tabListe: ["图", "表格"], //集中供水率对比tab
      tabListe2: ["图", "表格"],
      tabListe3: ["图", "表格"],
      tabListeIndex1: 0, //集中供水率对比 图表index
      tabListeIndex2: 0, //集中供水率对比 分类index
      sortup1: false, //超标指标分析 排序
      sortup2: false, //各市主要超标指标 排序
      area_bad_quality_table_column, // 区域超标指标数据表格表头
      area_bad_quality_table_data: [], // 区域超标指标数据表格数据
      area_bad_quality: [], // 区域超标指标数据
      city_bad_quality_table_column, // 超标指标数据表格表头
      city_bad_quality_table_data: [], // 区域超标指标数据表格数据
      city_bad_quality: [], // 区域超标指标数据
      province: "山西", //当前选择的省
      standard_count: 0, // 超标指标
      project_count: 0, // 超标工程
      badCoverPeo: 0, //超标覆盖人口
      detectionNum: 0, //检测指标
      coverPla: 0, //检测工程
      coverPeo: 0, //检测覆盖人口
      // 地图数据
      map_data: [],
      map_titles: [],
      map_title_colors: [],
      options: [
        {
          value: "1",
          label: "山西省"
        }
      ], //行政区划
      userAreaId: {
        id: 1
      },
      listParam: {
        areaIds: "1",
        name: "",
        natManageUnits: "",
        waterType: "0",
        wasuScales: "0",
        reportYear: "2020",
        updateMode: "0",
        pageSize: 5,
        pageNumber: 1
      },
      titleData: '各市（县）',
      titleDataRate: '超标指标合格率情况',
      titleDataNumber: '超标指标数量情况',
      tabChartIndex: 0,             //合格率 图表index
      tabTypeIndex: 0,              //合格率指标index
      tabChartNumIndex:0,           //合格数量index
      tabTypeNumIndex: 0,           //合格数量指标index
      tabTypeInner: 0,
      city_rate_column,             //各市超标指标合格率表头
      city_rate_table_data: [],     //各市超标指标合格率表格数据
      city_number_column,           //各市超标指标数量表头
      city_number_table_data: [],   //各市超标指标数量表格数据
      sortNum: false,               //合格数量 排序
      sortRate: false,              //合格率 排序
      county_column,                //县超标工程表表头
      county_column_table_data:[],  //县超标工程表表格数据
      detectIdList: [],
      typeList: [' 超标水样数',' 超标水样覆盖人口'],
      userLevel: localStorage.getItem('userLevel'),  //用户角色区分
    };
  },
  created() {
    var date = new Date();

    this.listParam.reportYear = date.getFullYear();
    this.year.unshift(date.getFullYear() - 1);
    for (let i = 0; i < 9; i++) {
      this.year = this.year.concat(date.getFullYear() + i);
    }
    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.userAreaId.id = area.areaId;
    getAreaTreeList(this.userAreaId).then(response => {
      this.options = response.data.list;
    });
    if(this.userLevel ==3){
      this.titleData = area.areaName
      this.city_rate_column[1].title = '工程类型'
      this.city_number_column[1].title = '工程类型'
    }
    //  初始化页面数据
    this.getAreaList();
    this.getDetectList();
  },
  mounted() {
    this.chartHeight = this.$refs.line6.offsetHeight+'px'
    document.getElementById("tableline3").style.display = "none";
  },
  methods: {
    areaChange(value) {
      this.listParam.areaIds = value[value.length - 1];
      this.param.areaId = value[value.length - 1];
      console.log(value);
    },
    async initData() {
      // 获取筛选条件
      await baseMenuList({
        menu: "水样类型"
      }).then(res => {
        // console.log('水样类型')
        // console.log(res)
        this.sampleTypes = res.data.list;
      });
      await baseMenuList({
        menu: "供水季节"
      }).then(res => {
        // console.log('供水季节')
        // console.log(res)
        this.seasons = res.data.list;
        // this.param.season = 1;
      });
      await baseMenuList({
        menu: "水源类型"
      }).then(res => {
        this.arr3 = res.data.list;
      });
      await baseMenuList({
        menu: "集中设施"
      }).then(res => {
        this.arr4 = res.data.list;
      });
      // 获取区域
      // 查询相关数据
      this.getDatas();
    },
    getAreaList() {
      let data = getDefaultArea();
      console.log(data);
      this.param.defaultAreaId = data.id;
      this.param.areaName = data.name;
      this.param.reportYear = 2020;

      if (!this.param.areaId) {
        this.param.areaId = this.param.defaultAreaId;
      }

      getAreas({
        id: this.param.areaId
      }).then(res => {
        if (res.code == 200) {
          this.areas = res.data.list;
        }
        this.initData();
      });
    },
    getDetectList(){
      let detectlistParam = this.param
//      delete detectlistParam.defaultAreaId;
//      delete detectlistParam.selectedAreaId;
      getDetectList(detectlistParam).then(response => {
        this.detectIdList = response.data.list
        if(this.detectIdList.length>0){
          this.tabTypeIndex = this.detectIdList[0].id
          this.tabTypeNumIndex = this.detectIdList[0].id
        }else{
          this.tabTypeIndex = 0
          this.tabTypeNumIndex = 0
        }
        this.getConsumMultiQualityByDetect()
        this.getConsumMultiQualityByDetectNum()
      })
    },
    //跳转
    toReportDetail(id) {
      var url = `/test/report/view?reportId=${id}`;
      window.location(url, "_blank");
    },
    //跳转
    router(x) {
      this.$router.push({
        path: x
      });
    },
    // 切换集中供水率对比
    tabListeCheck1(index) {
      this.tabListeIndex1 = index;
      if (index == 0) {
        document.getElementById("tuline3").style.display = "block";
        document.getElementById("tableline3").style.display = "none";
      } else {
        document.getElementById("tableline3").style.display = "block";
        document.getElementById("tuline3").style.display = "none";
      }
    },
    //
    waterProject3(index) {
      this.tabListeIndex2 = index;
      let datas = this.handleData(
        this.city_bad_quality.consumDetects,
        "area",
        this.tabListeIndex2
      );
      this.$chart.line6("line6", this.tabListeIndex2, datas);
    },


    //合格率图、表切换
    tabCheckChart(index){
      let _this = this;
      _this.tabChartIndex = index;
      _this.titleDataRate = index==0? '超标指标合格率情况':'超标指标水样合格率表';
      this.getConsumMultiQualityByDetect()
    },
    //合格率-不同指标切换
    changeType(index){
      let _this = this;
      _this.tabTypeIndex = index;
      this.getConsumMultiQualityByDetect()
    },
    //合格数量图、表切换
    tabCheckChartNum(index){
      let _this = this;
      _this.tabChartNumIndex = index;
      _this.titleDataNumber = index==0? '超标指标数量情况':'超标指标水样数量表'
      this.getConsumMultiQualityByDetectNum()
    },
    //合格数量-不同指标切换
    changeNumType(index){
      let _this = this;
      _this.tabTypeNumIndex = index;
      this.getConsumMultiQualityByDetectNum()
    },
    changeTypeInner(index){
      let _this = this;
      _this.tabTypeInner = index;
      this.getConsumMultiQualityByDetectNum()
    },
    //合格率图表排序
//    rateSort(){
//      let _this = this;
//      _this.sortRate = !_this.sortRate;
//      let datas = this.consum_multi_quality_detect
////      let datas = _this.handleData(
////        _this.consum_multi_quality_detect,
////        "area",
////        _this.tabTypeIndex
////      );
//      console.log(datas);
//      _this.$chart.lineRate("lineRate", _this.tabTypeIndex, datas,_this.sortRate);
//    },
    //合格数量图表排序
    numSort(){
      let _this = this;
      _this.sortNum = !_this.sortNum;
      this.getConsumMultiQualityByDetectNum()
    },


    // 集中供水率对比/排序
    sort1() {
      this.sortup1 = !this.sortup1;
      let datas = this.handleData(
        this.city_bad_quality.consumDetects,
        "area",
        this.tabListeIndex2
      );
      console.log(datas);
      this.$chart.line6("line6", this.tabListeIndex2, datas);
    },
    sort2() {
      this.sortup2 = !this.sortup2;
      let datas = this.handleData(this.area_bad_quality, "city");
      // 渲染图表
      if(document.getElementById('line7')) {
        this.$chart.line7("line7", this.tabListeIndex2, datas);
      }
    },
    //查询
    handleSearch(e) {
      e.preventDefault();
      this.getDatas();
    },
    //清除
    handleReset() {
      this.param.areaId = this.param.defaultAreaId;
      this.param.selectedAreaId = undefined;
      this.param.reportYear = 2020;
      this.param.sampleType = undefined;
      this.param.season = undefined;
      this.param.projectTypes = [];
      this.param.waterTypes = [];
      this.param.projectTypes = [];

      // this.form.reset()
      this.getDatas();
    },
    //多选年份
    handleChange(value) {
      console.log(`selected ${value}`);
      this.param.reportYear = value;
    },
    // 统计数据
    getDatas() {
      // 获取区域主要超标地图
      getConsumAreaBadQuality({
        areaIds: this.param.areaId,
        reportYear: this.param.reportYear,
        sampleTypes: this.param.sampleType,
        season: this.param.season,
        waterTypes: this.param.waterTypes,
        projectTypes: this.param.projectTypes,
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          // console.log('获取统计数据');
          console.log(res.data);
          this.area_bad_quality = res.data.list;
          // 表格 数据渲染
          this.city_bad_quality_table_data = res.data.list;
          // 图表 数据渲染
          let datas = this.handleData(this.area_bad_quality, "city");
          // 渲染图表
          if(document.getElementById('line7')) {
            this.$chart.line7("line7", this.tabListeIndex2, datas);
          }

          // 地图数据渲染
          // this.handleData(this.area_bad_quality, "map");
          // 渲染地图 TODO
          // this.$chart.initChinaMap("initChinaMap", this.province, this.map_data);
        }
      });
      // 获取各市主要超标指标
      getConsumBadQuality({
        areaIds: this.param.areaId,
        reportYear: this.param.reportYear,
        sampleTypes: this.param.sampleType,
        season: this.param.season,
        waterTypes: this.param.waterTypes,
        projectTypes: this.param.projectTypes,
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.city_bad_quality = res.data;

          // 超标指标 数据渲染
          this.standard_count = res.data.badDetectionNum;
          // 超标工程 数据渲染
          this.project_count = res.data.badCoverPla;

          // 超标覆盖人口 数据渲染
          this.badCoverPeo = res.data.badCoverPeo;
          // 检测指标 数据渲染
          this.detectionNum = res.data.detectionNum;
          // 检测工程 数据渲染
          this.coverPla = res.data.coverPla;
          // 检测覆盖人口 数据渲染
          this.coverPeo = res.data.coverPeo;

          // 表格 数据渲染
          this.area_bad_quality_table_data = res.data.consumDetects;

          // 图表 数据渲染
          let datas = this.handleData(
            this.city_bad_quality.consumDetects,
            "area",
            this.tabListeIndex2
          );
          // console.log(datas)
          // 渲染图表
          this.$chart.line6("line6", this.tabListeIndex2, datas);
        }
      });
      // 获取各市主要超标指标
      getConsumMap({
        areaIds: this.param.areaId,
        reportYear: this.param.reportYear,
        sampleTypes: this.param.sampleType,
        season: this.param.season,
        waterTypes: this.param.waterTypes,
        projectTypes: this.param.projectTypes,
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          // 地图数据渲染
          this.handleData(res.data.list, "map");
          console.log("地图数据");
          // console.log(res.data.list);
          let title = new Set();
          // 渲染地图的标尺
          for (let i in res.data.list) {
            let result = res.data.list[i];
            title.add(result.detectName);
          }

          for (let j of title) {
            let R = Math.floor(Math.random() * 255);
            let G = Math.floor(Math.random() * 255);
            let B = Math.floor(Math.random() * 255);
            let color = "rgb(" + R + ", " + G + ", " + B + ")";
            // 渲染地图数据
            this.map_titles.push({
              name: j,
              color: color
            });
            this.map_title_colors.push(color);
          }
          // 渲染地图 TODO
          if(document.getElementById('initShanxiMap')) {
            this.$chart.initShanxiMap(
              "initShanxiMap",
              this.province,
              this.map_data,
              this.map_title_colors
            );
          }
        }
      });
      this.getDetectList()
    },
    getConsumMultiQualityByDetect(){
      getConsumMultiQualityByDetect({
        areaId: this.param.areaId,
        detectId: this.tabTypeIndex,
        reportYear: this.param.reportYear,
        sampleTypes: this.param.sampleType,
        season: this.param.season,
        waterTypes: this.param.waterTypes,
        projectTypes: this.param.projectTypes,
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          // 图表 数据渲染
          let datas = res.data.list
          if(this.tabChartIndex==0) {
            //避免dom还未渲染，echarts获取dom元素报错——'getAttribute' of null
            if(document.getElementById('lineRate')){
              this.$chart.lineRate("lineRate", this.tabTypeIndex, datas);
            }
          }else{
            // 表格 数据渲染
            this.city_rate_table_data = res.data.list;
            this.city_rate_table_data.forEach((item,k)=>{
              let key = 'key';
              let value = k+1;
              item[key] = value
            })
            let list = {};
            this.detectIdList.forEach(item =>{
              if(item.id == this.tabTypeIndex){
                list = item
              }
            })
            this.city_rate_table_data.forEach(item=>{
              let key = 'overStandName';
              let value = list.descChinese;
              item[key] = value
            })
            console.log('111111',this.city_rate_table_data)

          }
        }
      });
    },
    getConsumMultiQualityByDetectNum(){
      getConsumMultiQualityByDetect({
        areaId: this.param.areaId,
        detectId: this.tabTypeNumIndex,
        reportYear: this.param.reportYear,
        sampleTypes: this.param.sampleType,
        season: this.param.season,
        waterTypes: this.param.waterTypes,
        projectTypes: this.param.projectTypes,
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          // 表格 数据渲染
          this.city_number_table_data = res.data.list;
          this.city_number_table_data.forEach((item,k)=>{
            let key = 'key';
            let value = k+1;
            item[key] = value
          })
          // 图表 数据渲染
          let datas = res.data.list
          if(this.tabChartNumIndex==0) {
            //避免dom还未渲染，echarts获取dom元素报错
            if(document.getElementById('lineNum')) {
              this.$chart.lineNum("lineNum", this.tabTypeNumIndex, datas, this.sortNum, this.tabTypeInner);
            }
          }else{
            let list = {};
            this.detectIdList.forEach(item =>{
              if(item.id == this.tabTypeNumIndex){
                list = item
              }
            })
            this.city_number_table_data.forEach(item=>{
              let key = 'overStandName';
              let value = list.descChinese;
              item[key] = value
            })
          }
        }
      });
    },
    handleChangeArea(value) {
      this.param.areaId = value;
      this.param.selectedAreaId = value;
    },
    handleChangeSampleType(value) {
      this.param.sampleType = value;
    },
    handleChangeSeason(value) {
      this.param.season = value;
    },
    // 切换水源类型
    handleChangeWaterSource(value) {
      console.log(value);
      this.param.waterTypes = value;
    },
    // 切换工程类型
    handleChangeProject(value) {
      console.log(value);
      this.param.projectTypes = value;
    },
    sortByNumber(a, b) {
      if (this.sortup1) {
        return (
          parseInt(b.goodPow ? b.goodPow : 0) -
          parseInt(a.goodPow ? a.goodPow : 0)
        );
      } else {
        return (
          parseInt(a.goodPow ? a.goodPow : 0) -
          parseInt(b.goodPow ? b.goodPow : 0)
        );
      }
    },
    // 对数据进行处理
    handleConsumpRrojects(index) {
      // alert("tabListeIndex1:" + this.tabListeIndex1 + "-index:" + index);
      let title = [];
      let data = [];
      this.data = [];
      this.data1 = [];
      this.map_data = [];
      this.area_pows.sort(this.sortByNumber);

      for (let i in this.area_pows) {
        let result = this.area_pows[i];
        // 渲染地图数据
        this.map_data.push({
          name: result.adName,
          value: result.qualityPow
        });

        // 水质达标率
        this.data1.push({
          key: i,
          name: result.adName,
          percent1: result.qualityPow
        });

        // 渲染集中供水率对比
        title.push(result.adName);
        // 如果是图表
        if (this.tabListeIndex1 == 0) {
          switch (index) {
            case 0: // 集中供水率
              data.push({
                value: result.focusWasePow,
                name: result.adName
              });
              break;
            case 1: // 自来水普及率
              data.push({
                value: result.wawPow,
                name: result.adName
              });
              break;
            case 2: // 规模化供水率
              data.push({
                value: result.scalePow,
                name: result.adName
              });
              break;
            case 3: // 供水保证率
              data.push({
                value: result.endPow,
                name: result.adName
              });
              break;
          }
        } else {
          // 如果是表格
          this.data.push({
            key: i,
            name: result.adName,
            percent1: result.focusWasePow,
            percent2: result.wawPow,
            percent3: result.endPow,
            percent4: result.scalePow
          });
        }
      }

      return {
        title: title,
        data: data
      };
    },
    sortByNumberA(a, b) {
      if (this.sortup2) {
        return (
          parseInt(b.detectId ? b.detectId : 0) -
          parseInt(a.detectId ? a.detectId : 0)
        );
      } else {
        return (
          parseInt(a.detectId ? a.detectId : 0) -
          parseInt(b.detectId ? b.detectId : 0)
        );
      }
    },
    // 处理数据
    handleData(datas, type) {
      let data = [];
      let title = [];
      let name = [];
      console.log(datas);
      console.log(this.sortByNumberA);
      if (!datas) {
        datas = [];
      }
      datas.sort(this.sortByNumberA);

      for (let i in datas) {
        let result = datas[i];
        if (type === "city") {
          title.push(result.adName);
          name.push(result.detectName);
          data.push({
            name: result.detectName,
            value: result.badDetectionNum || 0
          });
        } else if (type === "map") {
          // 渲染地图数据
          this.map_data.push({
            name: result.adName,
            value: result.badDetectionPow
          });
        } else {
          title.push(result.detectName);

          switch (this.tabListeIndex2) {
            case 0:
              // 覆盖人数
              data.push(result.badCoverPeo);
              break;
            case 1:
              // 工程处数
              data.push(result.badCoverPla);
              break;
            case 2:
              // 人口占比
              data.push(result.badPeoPow);
              break;
            case 3:
              // 工程占比
              data.push(result.badPlaPow);
              break;
          }
        }
      }
      for (let i in this.city_bad_quality_table_data) {
        this.city_bad_quality_table_data[i].key = parseInt(i) + 1;
      }
      return {
        title: title,
        name: name,
        data: data
      };
    }
  }
};
</script>

<style scoped lang="less">
#tableline3 /deep/ .ant-table-body {
  overflow-x: hidden !important;
}
#tableline4 /deep/ .ant-table-body {
  overflow-x: hidden !important;
}
.industry-second {
  width: 100%;
  background: #ffffff;
  .industry-second-up {
    padding: 30px 20px 15px 20px;
    border-bottom: 1px solid #e1e1e1;
  }
  .industry-second-ud {
    display: flex;
    padding: 20px 0 20px 0;
    .industry-second-udone {
      position: relative;
      cursor: pointer;
      width: 105px;
      text-align: center;
      div {
        width: 50px;
        height: 1px;
        background: #1890ff;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -4px;
        display: none;
      }
    }
    .progress-tabone-select {
      color: #1890ff;
      div {
        display: block;
      }
    }
  }
}
//图表-合格率
.industry-single{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .industry-single-inner{
        width: 100%;
        background: #ffffff;
        padding: 20px;
        .industry-thirdone-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .industry-thirdone-title-l {
                display: flex;
                align-items: center;
                font-weight: bold;
                font-size: 16px;
                .line {
                    width: 4px;
                    height: 16px;
                    background: #1890ff;
                    border-radius: 2px;
                }
                .title {
                    width: 120px;
                    margin-left: 8px;
                }
            }
            .industry-thirdone-title-r {
                display: flex;
                cursor: pointer;
                .industry-progress-tab {
                    width: 100%;
                    display: flex;
                    font-size: 12px;
                    justify-content: space-between;
                    // margin-top: 4px;
                    .industry-progress-tabone {
                        position: relative;
                        cursor: pointer;
                        margin-left: 14px;
                        font-size: 14px;
                        div {
                            width: 50px;
                            height: 1px;
                            background: #1890ff;
                            position: absolute;
                            transform: translate(-50%, -50%);
                            left: 50%;
                            bottom: -4px;
                            display: none;
                        }
                    }
                    .industry-progress-tabone2{
                        position: relative;
                        cursor: pointer;
                        margin-left: 14px;
                        font-size: 14px;
                        height: 30px;
                        line-height: 30px;
                        padding:0 5px;
                    }
                    .progress-tabone-select {
                        color: #1890ff;
                        div {
                            display: block;
                        }
                    }
                    .progress-tabone-select2{
                        border: 1px solid #e1e1e1;
                        height: 24px;
                        line-height: 24px;
                        padding: 0 10px;
                        color: #666;
                        font-size: 12px;
                    }
                    .progress-tabone-selectActive2{
                        border: 1px solid #6fbaff;
                        height: 24px;
                        line-height: 24px;
                        padding: 0 10px;
                        color: #1890ff;
                        font-size: 12px;
                    }
                    .progress-tabone-select-border{
                        border: 1px solid #e1e1e1;
                        height: 24px;
                        line-height: 24px;
                        padding: 0 10px;
                        color: #666;
                        font-size: 12px;
                    }
                    .progress-tabone-select-borderActive{
                        border: 1px solid #6fbaff;
                        height: 24px;
                        line-height: 24px;
                        padding: 0 10px;
                        color: #1890ff;
                        font-size: 12px;
                    }
                }
                .industry-thirdone-title-rdone {
                    width: 80px;
                    height: 24px;
                    line-height: 24px;
                    background: #ffffff;
                    border: 1px solid #e1e1e1;
                    font-size: 12px;
                    color: #999999;
                    text-align: center;
                }
                .industry-thirdone-title-rselect {
                    color: #ffffff;
                    background: #1890ff;
                    border: 1px solid #1890ff;
                }
            }
        }
        .linesort {
            right: 40px;
            top: 20px;
            position: absolute;
            z-index: 9;
            cursor: pointer;
            img {
                width: 21px;
                height: 19px;
            }
        }
    }
}
// 图表切换
.industry-six {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .industry-six-r {
    width: 61.5%;
    // height: 400px;
    background: #ffffff;
    padding: 20px;
    .industry-thirdone-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .industry-thirdone-title-l {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        .line {
          width: 4px;
          height: 16px;
          background: #1890ff;
          border-radius: 2px;
        }
        .title {
          width: 120px;
          margin-left: 8px;
        }
      }
      .industry-thirdone-title-r {
        display: flex;
        cursor: pointer;
        .industry-progress-tab {
          width: 100%;
          display: flex;
          font-size: 12px;
          justify-content: space-between;
          // margin-top: 4px;
          .industry-progress-tabone {
            position: relative;
            cursor: pointer;
            margin-left: 14px;
            font-size: 14px;
            div {
              width: 50px;
              height: 1px;
              background: #1890ff;
              position: absolute;
              transform: translate(-50%, -50%);
              left: 50%;
              bottom: -4px;
              display: none;
            }
          }
          .progress-tabone-select {
            color: #1890ff;
            div {
              display: block;
            }
          }
        }
        .industry-thirdone-title-rdone {
          width: 80px;
          height: 24px;
          line-height: 24px;
          background: #ffffff;
          border: 1px solid #e1e1e1;
          font-size: 12px;
          color: #999999;
          text-align: center;
        }
        .industry-thirdone-title-rselect {
          color: #ffffff;
          background: #1890ff;
          border: 1px solid #1890ff;
        }
      }
    }
    .linesort {
      right: 40px;
      top: 20px;
      position: absolute;
      z-index: 9;
      cursor: pointer;
      img {
        width: 21px;
        height: 19px;
      }
    }
  }
}
// 超标指标
.industry-six-l {
  width: 37%;
  // height: 400px;
  background: #ffffff;
  padding: 20px;
  .industry-thirdone-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .industry-thirdone-title-l {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      .line {
        width: 4px;
        height: 16px;
        background: #1890ff;
        border-radius: 2px;
      }
      .title {
        width: 120px;
        margin-left: 8px;
      }
    }
  }
}
.industry-six-l-con {
  width: 96%;
  /*height: 90%;*/
  margin: 0px auto;
  margin-top: 30px;
}
.industry-six-l-con-p {
  display: flex;
  justify-content: space-between;
}
.industry-six-l-con-l {
  width: 50%;
}
.industry-six-l-con-r {
  width: 50%;
}
.industry-six-l-con-top {
  width: 100%;
  margin: 20% 0px;
  font-size: 16px;
}
.industry-six-l-con-btn {
  cursor: pointer;
  width: 214px;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(246, 169, 59, 100);
  color: rgba(251, 247, 247, 100);
  font-size: 20px;
  text-align: center;
  font-family: Microsoft Yahei;
  text-align: center;
  line-height: 40px;
  margin: 20px auto;
}
// 地图

.rates-one {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  .rates-one-l {
    width: 100%;
    height: 1000px;
    background: #ffffff;
    padding: 20px;
    overflow: hidden;
    .industry-thirdone-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .industry-thirdone-title-l {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        .line {
          width: 4px;
          height: 16px;
          background: #1890ff;
          border-radius: 2px;
        }
        .title {
          margin-left: 8px;
        }
      }
    }
    .rates-onemap-con {
      height: 100%;
      position: relative;
      .legend {
        position: absolute;
        bottom: 56px;
        left: 20px;
        z-index: 9;
        .legendone {
          display: flex;
          align-items: center;
          margin-top: 8px;
          .block {
            width: 30px;
            height: 20px;
            margin-right: 14px;
          }
          .text {
            font-size: 20px;
            color: #666666;
          }
        }
      }
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #28282c;
        position: absolute;
        bottom: 60px;
        left: 70%;
        z-index: 9;
      }
      .map {
        margin: 0 auto;
        // background: rgb(246, 246, 246);
      }
    }
  }
  .rates-one-r {
    width: 35%;
    height: 650px;
    background: #ffffff;
    padding: 20px;
    .industry-thirdone-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .industry-thirdone-title-l {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        .line {
          width: 4px;
          height: 16px;
          background: #1890ff;
          border-radius: 2px;
        }
        .title {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
